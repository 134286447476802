.section-question {
    background-image: url("/img/user/background-list-question.png");
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center top;
    min-height: calc(100vh - 130px);
}

.title-header {
    margin: auto;
}

.content-question {
    width: 800px;
    margin: auto;
}

.background-question-item {
    background-color: #FFFFFF;
}

.padding-header {
    padding-top: 80px;
}

.list-question-item {
    padding: 20px 15px 5px 32px;
}

.line-height-56 {
    line-height: 56px;
}

.color-3B4043 {
    color: #3B4043;
}

.color-173B50 {
    color: #173B50;
}

.font-size-18 {
    font-size: 18px;
}

.font-size-16 {
    font-size: 16px;
}

.line-height-27 {
    line-height: 27px;
}

.question {
    display: flex;
    align-items: center;
}

.line-height-28 {
    line-height: 28px;
}

.active-transform {
    transform: rotate(180deg);
}

.font-size-answer {
    font-size: 16px;
}

.line-height-answer {
    line-height: 28px;
}

.font-size-question {
    font-size: 18px;
}

.line-height-question {
    line-height: 27px;
}

@media (max-width: 800px) {
    .content-question {
        width: 100%;
        margin: auto;
        padding-left: 20px;
        padding-right: 20px;
    }

    .list-question-item {
        padding-left: 20px;
        padding-right: 5px;
        padding-top: 16px;
        padding-bottom: 1px;
    }

    .font-size-answer {
        font-size: 14px;
    }

    .font-size-question-sp {
        font-size: 28px;
    }

    .line-height-answer {
        line-height: 25px;
    }

    .font-size-question {
        font-size: 16px;
    }

    .line-height-question {
        line-height: 24px;
    }
}

.question-item {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.width-question-main {
    width: 100%;
}

.width-question-icon {
    width: 5%;
}
